<template>
  <modal id="number-students-modal">
    <template #body>
      <b-overlay :show="sendingData" rounded="sm">
        <template #overlay>
          <div class="text-center">
            <b-spinner label="Spinning"/>
          </div>
        </template>
        <p class="modal-subtitle text-center">
          Please enter the number of students in your classroom:
        </p>
        <b-input type="number" maxlength="25" v-model="form.numberStudents" :errors="errors.numberStudents"
                 custom-class="common-input common-input--primary"
                 class="number-students-input"
                 placeholder="Number Students"/>
      </b-overlay>
    </template>
    <template #footer>
      <b-button @click="saveNumberStudents" btn-text="Ok" :disabled="sendingData"/>
    </template>
  </modal>
</template>

<script>
import {Modal} from "@/core/components/Modal";
import BInput from "@/core/components/FormInput/Input";
import {Button} from "@/core/components/Button";
import validateForms from "@/core/Mixins/validate-forms";
import {CourseMixins} from "@/modules/Classroom/Mixins";
import {ClassroomService} from "@/core/Services";

export default {
  name: "StudentsNumberModal",
  components: {
    Modal,
    BInput,
    'b-button': Button,
  },
  mixins: [validateForms, CourseMixins],
  props: {
    classroom: Object,
  },
  data() {
    return {
      form: {
        numberStudents: 0,
      },
      validations: {
        numberStudents: {
          validate: 'required|number|numberStudentsAllowed',
        }
      },
      sendingData: false
    }
  },
  mounted() {

  },
  methods: {
    saveNumberStudents () {
      if (!this.sendingData && this.isValidForm()) {
        this.sendingData = true
        const data = {classroom_id: this.classroom.id, students_number: this.form.numberStudents}

        ClassroomService.saveStudentNumber(data).then((resp) => {
          if (resp.success) {
            this.$bvModal.msgBoxOk('successful!', {
              size: 'sm',
              dialogClass: "modal-message-box",
              noCloseOnBackdrop: true,
              centered: true
            }).then(async () => {
              this.$bvModal.hide('number-students-modal')
              await this.getClassroomNoOverlay(this.classroom.id)
              this.$emit('save-number-students')
            })
          } else {
            const errorMessage = resp.message ? resp.message : 'Error when saving student number.'
            this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
              size: 'sm',
              dialogClass: 'modal-message-box',
              centered: true
            })
          }
        }).catch((res) => {
          const errorMessage = res.message ? res.message : 'Error reporting.'
            this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
              size: 'sm',
              dialogClass: 'modal-message-box',
              centered: true
            })
        }).finally(() => this.sendingData = false)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.number-students-input
  margin-top 15px

@media (max-width 495px)
  .size-text-btn
    font-size 10px

</style>
