<template>
  <div class="connect-container">
    <h2 class="title">Connect With Other Libraries</h2>
    <p class="message" v-if="!requestList.length">There are currently no requests. Be sure to create your own request and to check back often. This list is subject to frequent changes.</p>
    <div class="card-connection-wrapper">
      <div class="card-connection" v-for="request in requestList" :key="request.id">
        <div class="card-left">
          <div class="avatar-container">
            <b-avatar class="avatar"
                      size="100%"
                      :src="request.avatar"/>
          </div>
          <p class="subtitle subtitle--name">{{request.studentAges}}</p>
          <b-form-checkbox class="custom-checkbox-primary"
                           v-model="request.hideRequest"
                           @input="hideRequest(request)"
                           :disabled="false">
            Don´t Show
          </b-form-checkbox>
        </div>
        <div class="card-right">
          <h2 class="title title--card">{{truncateString(request.name, 30)}}</h2>
          <p class="subtitle">{{request.schoolLocation}}</p>
          <p class="subtitle subtitle--description">{{truncateString(request.requestText, 120)}}</p>
          <div class="topics-container">
            <p class="subtitle">Topic(s):</p>
            <div class="topics-list">
              <p class="subtitle" v-for="(topic, idx) in request.topics" :key="topic + idx">{{topic}}</p>
            </div>
          </div>
          <div class="container-buttons">
            <b-button :disabled="false"
                      class="button"
                      @click="showLibraryDetail(request)"
                      btn-text="Learn More..."/>
            <b-button class="button button--connect"
                      :disabled="false"
                      @click="connect(request)"
                      btn-text="Connect"/>
          </div>
        </div>
      </div>
    </div>
    <p class="show-all" v-if="showLink" @click="clickShowAll">Show All...</p>
    <library-request-detail :request-detail="requestSelected" ref="library-request-detail" @connect-librarian="connect"/>
    <b-overlay no-wrap :show="loading"/>
  </div>
</template>

<script>
import {Button} from "@/core/components/Button"
import LibraryRequestDetail from './LibraryRequestDetail'
import {LibrarianService} from '@/core/Services'
import {UtilsMixins} from "@/core/Mixins"

export default {
  name: "ConnectOtherLibraries",
  mixins: [UtilsMixins],
  components: {
    'b-button': Button,
    LibraryRequestDetail
  },
  async mounted() {
    await this.updateRequestList()
    this.getTopics()
    this.interval = setInterval(() => {
      this.updateRequestList(false)
    }, 15000)
  },
  data() {
    return {
      requestSelected: {},
      loading: false,
      interval: null,
      requestData: [],
      requestList: [],
      hiddenRequestList: []
    }
  },
  methods: {
    getHiddenRequestList() {
      LibrarianService.getConnectionsRequestHidden().then((res) => {
        if (res.success) {
          this.hiddenRequestList = res.data
        } else {
          const errorMessage = res.message ? res.message : 'Error hiding request. Tracking views error.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
            size: 'sm',
            dialogClass: 'modal-message-box',
            centered: true
          })
        }
      })
    },
    async hideRequest(request) {
      if (request.hideRequest) {
        await LibrarianService.hideRequest(request.id).then((res) => {
          if (!res.success) {
            const errorMessage = res.message ? res.message : 'Error hiding request. Tracking views error.'
            this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
              size: 'sm',
              dialogClass: 'modal-message-box',
              centered: true
            })
          }
        })
        this.updateRequestList()
      }
    },
    topicLabel (topics = []) {
      if (!topics?.length) {
        return ['Any']
      }
      if (topics?.length === this.topics?.length) {
        return ['Any']
      }
      return topics
    },
    updateRequestList(overlay = true) {
      overlay && (this.loading = true)
      LibrarianService.getConnectionsRequestNotHidden().then((res) => {
        if (res.success) {
          const requestConnections = Array.isArray(res.data) ?  res.data : Object.values(res.data)
          this.requestList = requestConnections.map((request) => ({
            id: request.id,
            name: request.name,
            schoolLocation: request.school_location,
            requestText: request.text_request,
            avatar: request.avatar,
            studentAges: request.age_range_id ? this.age_ranges?.find((item) => item.id === request.age_range_id)?.name : request.age_range_id,
            aboutMe: request.about_me,
            numberStudent: request.number_students,
            hideRequest: false,
            topics: this.topicLabel(request.topics)
          }))
        } else {
          const errorMessage = res.message ? res.message : 'Error saving the request.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
            size: 'sm',
            dialogClass: 'modal-message-box',
            centered: true
          })
        }
      }).finally(() => {
        this.loading = false
      })
      this.getHiddenRequestList()
    },
    connect(request) {
      this.loading = true
      LibrarianService.connectWithLibrarian(request.id).then((res) => {
        if (res.success) {
          this.$bvModal.msgBoxOk('Successful Connection!.', {
            size: 'sm',
            dialogClass: 'modal-message-box',
            centered: true
          }).then(() => {
            this.$emit('on-connect-request')
          })
        } else {
          const errorMessage = res.message ? res.message : 'Error saving the request.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
            size: 'sm',
            dialogClass: 'modal-message-box',
            centered: true
          })
        }
      }).finally(async () => {
        await this.updateRequestList()
        this.loading = false
      })
    },
    showLibraryDetail(request) {
      this.requestSelected = request
      this.$nextTick(() => this.$refs['library-request-detail'].open())
      LibrarianService.trackingRequestViews(request.id).then((res) => {
        if (!res.success) {
          const errorMessage = res.message ? res.message : 'Tracking views error.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
            size: 'sm',
            dialogClass: 'modal-message-box',
            centered: true
          })
        }
      })
    },
    truncateString(str, num = 10) {
      return str.length > num ? str.slice(0, num) + "..." : str
    },
    async clickShowAll() {
      await Promise.all(this.hiddenRequestList.map(async (request) => {
        await LibrarianService.showRequest(request.id)
      }))
      this.updateRequestList()
    }
  },
  computed: {
    showLink() {
      return this.hiddenRequestList.length
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.connect-container
  font font-opensans-regular
  margin 0 10px
  font-size 20px
  border 2px solid color-4
  border-radius 15px
  height 100%
  padding 10px 15px
  width 70%
  position relative

  @media(max-width 1000px)
    margin-right 0
  @media(max-width 600px)
    margin 0
    width 100%
    padding 10px 4px

  .title
    font font-opensans-bold
    font-size 22px

  .subtitle
    font font-opensans-semibold
    margin 0

  .message
    text-align center
    position absolute
    font font-opensans-semibold
    margin 0
    top 50%
    left 0
    transform translateY(-50%)
    padding 10px
    @media(max-width 600px)
      position static
      transform none

  .show-all
    cursor pointer
    font font-opensans-semibold
    margin 0
    color color-link
    padding 0 10px
    padding-top 15px

  .card-connection-wrapper
    max-height 710px
    overflow-y auto
    height 710px
    @media(max-width 600px)
      height auto

  .card-connection
    display flex
    width 96%
    border 2px solid color-4
    border-radius 15px
    padding 15px 15px 15px 0
    margin 0 auto
    margin-bottom 10px
    max-width 566px
    @media(max-width 485px)
      padding 15px 0 15px 0

    .card-left
      width 30%

      .avatar-container
        margin 0 auto
        width 80%
        height 0
        padding-top 80%
        position relative

        .avatar
          margin 0 auto
          display block
          position absolute
          top 0
          left 0

      .subtitle--name
        margin 10px 0

      .subtitle--name, .custom-checkbox-primary
        text-align center

    .card-right
      width 70%
      display flex
      flex-direction column

      .subtitle--description
        margin-bottom 10px

      .topics-container
        display flex
        margin-bottom 10px

        .topics-list
          margin-left 6px

      .container-buttons
        width 100%
        display flex
        align-items flex-end
        height 50%
        justify-content space-between
        flex-wrap wrap

        .button
          font font-opensans-semibold
          text-transform capitalize
          height fit-content

        .button--connect
          margin 0
          margin-top 5px

@media(max-width 800px)
  .connect-container
    .title--card
      font-size 15px

    .subtitle
      font-size 12px

    .card-connection
      .card-left
        .custom-checkbox-primary
          padding-left 1.8rem
          ::v-deep
            .custom-control-label
              font-size 12px

      .card-right
        .container-buttons
          .button
            font-size 12px

          .button--connect
            margin 0
            margin-top 5px
</style>
