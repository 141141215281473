<template>
  <div class="connection-container">
    <h2 class="title">My Connection Requests</h2>
    <p class="message" v-if="!myRequestList.length">Click the button below to create a connection request.</p>
    <div class="card-connection-wrapper">
      <div class="card" v-for="request in myRequestList" :key="request.id">
        <h2 class="title title--card">{{truncateString(request.name, 30)}} ({{request.views}} views)</h2>
        <p class="subtitle subtitle--description">{{truncateString(request.requestText, 80)}}</p>
        <div class="footer-card">
          <p class="subtitle subtitle--expire">Expires: {{formattedDate(request.expire)}}</p>
          <b-button :disabled="false"
                    @click="editRequest(request)"
                    btn-text="Edit"/>
        </div>
      </div>
    </div>
    <b-button :disabled="false"
              @click="newRequest"
              class="button-new-request"
              btn-text="New Request"/>
    <new-connection-request-popup :request-detail="requestSelected" ref="newRequest" @save-request="getRequestList()"/>
    <b-overlay no-wrap :show="loading"/>
  </div>
</template>

<script>
import {Button} from "@/core/components/Button"
import NewConnectionRequestPopup from './NewConnectionRequestPopup'
import {LibrarianService} from '@/core/Services'
import moment from 'moment'

export default {
  name: "MyConnectionRequests",
  components: {
    'b-button': Button,
    NewConnectionRequestPopup
  },
  mounted() {
    this.getRequestList()
  },
  data() {
    return {
      loading: false,
      myRequestList: [],
      requestSelected: {}
    }
  },
  methods: {
    getRequestList() {
      this.loading = true
      LibrarianService.getMyRequests().then((res) => {
        if (res.success) {
          this.myRequestList = res.data.map((request) => ({
            id: request.id,
            name: request.name,
            requestText: request.text_request,
            expire: request.expire_at,
            studentAges: request.age_range_id,
            topics: request.librarian_topics_id,
            views: request.views_number,
          }))
        } else {
          const errorMessage = res.message ? res.message : 'Error saving the request.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
            size: 'sm',
            dialogClass: 'modal-message-box',
            centered: true
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    editRequest(request) {
      this.requestSelected = request
      this.$nextTick(() => this.$refs.newRequest.open())
    },
    newRequest() {
      this.requestSelected = {}
      this.$nextTick(() => this.$refs.newRequest.open())
    },
    truncateString(str, num = 10) {
      return str.length > num ? str.slice(0, num) + "..." : str
    },
    formattedDate(date) {
      return date ? moment(date).format('DD MMM YYYY') : date
    }
  }
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.connection-container
  font font-opensans-regular
  font-size 20px
  margin-bottom 30px !important
  border 2px solid color-4
  border-radius 15px
  height 100%
  padding 10px
  width 100%
  position relative

  .title
    font font-opensans-bold
    font-size 22px

  .subtitle
    font font-opensans-semibold
    margin 0

  .message
    text-align center
    position absolute
    font font-opensans-semibold
    margin 0
    top 50%
    left 0
    transform translateY(-50%)
    padding 10px
    @media(max-width 1000px)
      position static
      transform none
    @media(max-width 600px)
      position static
      transform none

  .card-connection-wrapper
    max-height 680px
    overflow-y auto
    height 680px
    @media(max-width 1000px)
      height auto

  .card
    width 98%
    margin 0 auto
    border 2px solid color-4
    border-radius 15px
    padding 10px
    margin-bottom 10px

    .subtitle--description
        max-height 70px

    @media(max-width 600px)
      .title--card, .subtitle--expire
        font-size 15px

      .subtitle--description
        font-size 12px

    .footer-card
      margin-top 12px
      display flex
      justify-content space-between

  .button-new-request
    margin 0 auto
    position relative
    top 20px
</style>
