<template>
  <div class="library-wrapper">
    <div class="library-content">
      <div class="about-me-container">
        <about-me-library ref="about-me-library"/>
        <connect-other-libraries @on-connect-request="updateActiveClassrooms"/>
      </div>
      <div class="connections-container">
        <my-connection-requests/>
      </div>
    </div>
  </div>
</template>

<script>
import AboutMeLibrary from '../Components/LibraryConnections/AboutMeLibrary'
import ConnectOtherLibraries from '../Components/LibraryConnections/ConnectOtherLibraries'
import MyConnectionRequests from '../Components/LibraryConnections/MyConnectionRequests'

export default {
  name: "LibraryConnectionsPage",
  components: {
    AboutMeLibrary,
    ConnectOtherLibraries,
    MyConnectionRequests
  },
  data () {
    return {
      sendingRequest: false,
      selectedInvitation: null,
      invitations: [],
    }
  },
  props: {
    invitation_id: {
      type: Number,
      required: false
    }
  },
  methods: {
    updateActiveClassrooms () {
      this.$refs['about-me-library'].getActiveClassrooms()
    }
  }
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

.library-wrapper
  width 100%
  display flex
  justify-content center

  .library-content
    max-width 1300px
    display flex
    margin 0 15px
    margin-top 40px
    margin-bottom 20px
    min-height calc(100vh -(80px + 50px))
    width 100%

    .about-me-container
      width 70%
      display flex

    .connections-container
      width 30%
      display flex

@media(max-width 1000px)
  .library-wrapper
    .library-content
      flex-direction column

      .about-me-container
        width 100%
        margin-bottom 10px

      .connections-container
        width 100%

@media(max-width 600px)
  .library-wrapper
    .library-content
      .about-me-container
        width 100%
        flex-direction column
        margin-bottom 10px

      .connections-container
        width 100%
</style>
