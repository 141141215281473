import {mapActions, mapGetters} from 'vuex';

export const MyClassRoomsMixins = {
  computed: {
    ...mapGetters({
      classrooms: 'MyClassroomsModule/getMyClassrooms',
      isLoadingClassrooms: 'MyClassroomsModule/isLoadingClassrooms',
      isLoadingProfile: 'UserModule/isLoadingProfile',
      user: 'UserModule/getUser',
      isLoadingMiscData: 'MiscModule/isLoadingMiscData',
      payment_methods: 'MiscModule/getPaymentMethods',
      levels: 'MiscModule/getLevels',
      subjects: 'MiscModule/getSubjects',
      languages: 'MiscModule/getLanguages',
      age_ranges: 'MiscModule/getAgeRanges',
    })
  },
  methods: {
    ...mapActions({
      getClassrooms: 'MyClassroomsModule/getClassrooms',
      getProfile: 'UserModule/getProfile',
      updateProfile: 'UserModule/updateProfile',
      getMiscData: 'MiscModule/fetchMiscData',
      getPaymentMethods: 'MiscModule/fetchPaymentMethods',
      getLevels: 'MiscModule/fetchLevels',
      getSubjects: 'MiscModule/fetchSubjects',
      getLanguages: 'MiscModule/fetchLanguages',
      getAgeRanges: 'MiscModule/fetchAgeRanges',
    })
  }
};
