<template>
  <div class="teacher-chat enter expand" :class="chatWrapperClasses" ref="teacher-chat-wrapper"
       @mouseenter="mouse_enter = true"
       @mouseleave="mouse_enter = false">
    <h3 class="teacher-chat__title" v-show="expanded">Chat with Global Partner</h3>
    <!--  <div class="teacher-chat">-->
    <!--    <i class="icon-message-board" aria-hidden="true" v-show="!expanded" @click="openChat"></i>-->
    <div class="chat  enter expand" :class="chatClasses" style="">
      <div class="header">
        <span class="title" v-for="(name, idx) in chatParticipants" :key="name + idx">{{ name }}<span v-show="idx !== chatParticipants.length - 1">,</span></span>
        <!--        <button @click="closeChat">-->
        <!--          <i class="fa fa-times" aria-hidden="true"></i>-->
        <!--        </button>-->
      </div>
      <ul class="messages" ref="teacher-chat-messages" @scroll="onListScroll">
        <li
          :class="{
                    'messages-self':message.from_teacher_id === user.id ,
                    'messages-partner':message.from_teacher_id !== user.id,
                    'read':message.status === 'read'
                  }"
          v-for="message in chat_messages"
          :key="message.id"
          :data-id="message.id"
        >
          <b-avatar v-if="message.from_teacher_id !== user.id" :src="message.from_teacher.avatar"/>

          <div class="text">
            <p v-if="(message.from_teacher_id !== user.id) && (isLibrarian || hasTeacherAssistant)" class="name-partner">{{ message.from_teacher.full_name }}</p>
            <a v-if="message.attachment"
              download
              :href="message.attachment"
              style="cursor: pointer; text-decoration: underline"
              class="attachment text-message">{{ message.attachment }}</a>

            <pre v-html="message.message"
                v-if="message.message"
                class="text-message"/>

            <div class="text-time">{{setTimestamp(message.created_at)}}</div>
          </div>

        </li>
      </ul>
      <div class="footer">
        <div class="container-text-area">
          <div id="chat-attachment" v-show="attachment">
            <upload-file @File:loaded="onFileLoaded"
                         ref="upload-file"
                         v-model="attachment"
                         :hideButton="true"
                         :currentFile="selected_attachment ? selected_attachment.name : ''"/>
          </div>

          <b-form-textarea class="text-box"
                           id="text-box"
                           rows="1"
                           max-rows="5"
                           ref="teacher-chat-text-box"
                           maxlength="1000"
                           :disabled="textBoxDisabled || classroomExchangeExpired"
                           @keydown="onMetaAndEnter"
                           v-model="chatTextBox"/>
        </div>

        <button @click="onAttachment" :disabled="disabledButtons" class="button-upload">
          <span class="icon-upload"></span>
        </button>

        <button id="sendMessage" @click="sendNewMessage" :disabled="disabledButtons">send</button>
      </div>
    </div>
  </div>
</template>

<script>
import {UtilsMixins} from "@/core/Mixins";
import {ClassroomService, UploadService} from "@/core/Services";
import {ExchangesMixins} from "@/modules/MyClassrooms/Mixins"
import UploadFile from "@/core/components/UploadFileAttachLink/UploadFile";
import {TrackingActivityService} from '@/core/Services'
import {TrackingTypes} from "@/core/Utils"
import {userTypeMixins} from "@/core/Mixins"
import moment from 'moment'

export default {
  name: "TeacherChat",
  mixins: [UtilsMixins, ExchangesMixins, userTypeMixins],
  components: {
    UploadFile
  },
  data() {
    return {
      chat: null,
      chatTextBox: null,
      chatMessagesList: null,
      entered: false,
      expanded: false,
      textBoxDisabled: true,
      textBoxMessage: '',
      chat_messages: [],
      is_partner_online: false,
      attachment: null,
      selected_attachment: null,
      sendingMessage: false,
      mouse_enter: false,
      chatTextBoxElement: null
    }
  },
  props: {
    partner: {
      required: true
    },
    classroom_id: {
      type: Number,
      required: true
    },
    class_pairing_id: {
      type: Number,
      required: true
    },
    teacher_partner_id: {
      type: Number,
      required: true
    },
    open_chat: {
      required: false
    },
  },
  async mounted() {
    this.chat = this.$refs['teacher-chat-wrapper']
    this.chatTextBoxElement = document.getElementById('text-box')
    this.chatMessagesList = this.$refs['teacher-chat-messages']
    this.$echo.options.auth.headers.Authorization = 'Bearer ' + this.user.token

    this.expandChatBox()

    await ClassroomService.getChatMessages(this.classroom_id).then(response => {
      this.chat_messages = response.data
    })

    this.listenToMessageEvent()
    this.joinToChatRoom()
    this.$nextTick(function () {
      this.scrollDownChat()
    })
  },
  methods: {
    onListScroll() {
      const el = document.querySelector(".messages-partner:not(.read)");

      if (el) {
        const eleTop = el.offsetTop;
        const eleBottom = eleTop + el.clientHeight;

        const containerTop = this.chatMessagesList.scrollTop;
        const containerBottom = containerTop + this.chatMessagesList.clientHeight;

        const visible = (
          (eleTop >= containerTop && eleBottom <= containerBottom)
        );

        if (visible) {
          let message = this.chat_messages.find(message => message.id === parseInt(el.dataset.id))
          if (message) {
            message.status = 'read'
            ClassroomService.notifyChatMessageRead(this.classroom_id, message.id)
          }
        }
      }
    },
    setTimestamp(time) {
      return moment(time).format('HH:mm') + ' | ' + moment(time).format('MM/DD/YYYY')
    },
    openChat() {
      if (!this.expanded) {
        this.expandChatBox()
        this.chatTextBoxElement.focus();
        this.scrollDownChat()
        this.joinToChatRoom()
      }
    },
    scrollDownChat() {
      setTimeout(() => {
        const el = this.chatMessagesList.querySelector(".messages-partner:not(.read)");

        if (!el) {
          this.chatMessagesList.scrollTop = this.chatMessagesList.scrollHeight;
        } else {
          this.chatMessagesList.scrollTop = el.offsetTop;
        }
      }, 300)
    },
    expandChatBox() {
      this.expanded = true
      this.entered = true
      this.textBoxDisabled = false
    },
    listenToMessageEvent() {
      this.$echo.channel('chat-message-' + this.class_pairing_id).listen('.ChatMessage', (e) => {
        if (this.teacher_partner_id === e.message.from_teacher_id) {
          if (!this.chat_messages.some(message => message.id === e.message.id)) {
            this.chat_messages.push(e.message)
          }
        }
      });
    },
    joinToChatRoom() {
      this.$echo.join('chat-room-' + this.class_pairing_id)
        .here((teachers) => {
          console.log('here', teachers)
          if (teachers.find(teacher => teacher.id === this.teacher_partner_id) !== undefined) {
            this.is_partner_online = true
          }
        })
        .joining((teacher) => {
          console.log('joining', teacher)
          if (teacher.id === this.teacher_partner_id) {
            this.is_partner_online = true
          }
        })
        .leaving((teacher) => {
          console.log('leaving', teacher)
          if (teacher.id === this.teacher_partner_id) {
            this.is_partner_online = false
          }
        });
    },
    leaveChatRoom() {
      this.$echo.leave('chat-room-' + this.class_pairing_id);
    },
    closeChat() {
      console.log('closeChat')
      this.expanded = false
      this.entered = false
      this.textBoxDisabled = true
      this.leaveChatRoom()
    },
    onFileLoaded(files) {
      if (files.length > 0) {
        this.selected_attachment = files[0]

        this.attachment = URL.createObjectURL(files[0])
      }
    },
    onAttachment() {
      this.$refs['upload-file'].openUploadFile()
    },
    async sendNewMessage() {
      const textBoxMessage = this.chatTextBox
      if ((!textBoxMessage && !this.selected_attachment) || this.sendingMessage) return;

      this.sendingMessage = true

      if (this.selected_attachment) {
        const result = await UploadService.uploadFile(this.selected_attachment)

        this.attachment = result.name
      }

      const response = await ClassroomService.sendChatMessages(this.classroom_id, {
        message: textBoxMessage,
        attachment: this.attachment,
        notify: !this.is_partner_online
      })

      if (response.success) {
        this.chat_messages.push(response.data)
      } else {
        this.$bvModal.msgBoxOk(response.message, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
      }

      // clean out old message
      this.chatTextBox = '';

      // clean out old attachment
      this.selected_attachment = null
      this.attachment = null
      this.sendingMessage = false

      // focus on input
      this.chatTextBoxElement.focus();
      this.chatMessagesList.scrollTop = this.chatMessagesList.scrollHeight
      TrackingActivityService.trackingActivityTeachers(TrackingTypes.sendPartnerMessage)
    },
    onMetaAndEnter(event) {
      if ((event.metaKey || event.ctrlKey) && event.keyCode === 13) {
        this.chatTextBox = this.chatTextBox + "<div><br></div>"
        this.chatTextBoxElement.scrollTop = this.chatTextBoxElement.scrollHeight;

        const range = document.createRange();
        range.selectNodeContents(this.chatTextBox);
        range.collapse(false);

        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);

      } else if (event.keyCode === 13) {
        event.preventDefault()
        this.sendNewMessage();
      }
    }
  },
  computed: {
    chatWrapperClasses() {
      return {
        enter: !!this.entered,
        expand: !!this.expanded,
      }
    },
    chatClasses() {
      return {
        enter: !!this.entered,
      }
    },
    disabledButtons() {
      return this.textBoxDisabled || this.sendingMessage || this.classroomExchangeExpired
    },
    chatParticipants() {
      const users = [
        this.myClassroom.teacher?.full_name,
        this.partner.full_name,
        this.myClassroom?.teacher_assistants[0]?.full_name,
        this.partner?.classrooms[0]?.teacher_assistants[0]?.full_name,
      ]
      return users.filter(user => user && user !== this.user.full_name) // Filters out null, undefined, empty strings, and the user's name
    },
    hasTeacherAssistant() {
      return !!this.myClassroom.teacher_assistants[0]?.id
    }
  },
  destroyed() {
    this.leaveChatRoom()
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"
// Imports
// --------------------------------------
//@import 'https://fonts.googleapis.com/css?family=Noto+Sans';
// Variables
// --------------------------------------
$scrollbar-width = 5px;
$chat-messages-color = color-1;
$chat-messages-bg-color = color-3;
$chat-messages-border-color = color-3;

$chat-messages-partner-color = color-42;
$chat-messages-partner-bg-color = color-23;
$chat-messages-partner-border-color = color-3;

$chat-thread-bgd-color = color-3;
$chat-thread-msg-arrow-size = 10px;
$chat-thread-avatar-size = 25px;

$chat-thread-offset = 45px // #{$chat-thread-avatar-size + 20px};
$default-shadow-color = color-4;
// Mixins
// --------------------------------------
fancy-background() {
  background: color-1;
  //background: -moz-linear-gradient(-45deg, #183850 0, #183850 25%, #192C46 50%, #22254C 75%, #22254C 100%);
  //background: -webkit-linear-gradient(-45deg, #183850 0, #183850 25%, #192C46 50%, #22254C 75%, #22254C 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

fancy-scrollbar() {
  &::-webkit-scrollbar {
    width: $scrollbar-width;
  }
  &::-webkit-scrollbar-track {
    border-radius: $scrollbar-width;
    background-color: rgba(25, 147, 147, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: $scrollbar-width;
    background-color: $chat-thread-bgd-color;
  }
}

scrolling-shadows($background-color = transparent, $shadow-intensity = 0.5, $shadow-color = $default-shadow-color, $cover-size = 40px, $shadow-size = 14px) {
  // Shadow covers
  background: linear-gradient($background-color 30%, rgba($background-color, 0)), linear-gradient(rgba($background-color, 0), $background-color 70%) 0 100%, radial-gradient(50% 0, farthest-side, rgba($shadow-color, $shadow-intensity), rgba($shadow-color, 0)), radial-gradient(50% 100%, farthest-side, rgba($shadow-color, $shadow-intensity), rgba($shadow-color, 0)) 0 100%;
  background: linear-gradient($background-color 30%, rgba($background-color, 0)), linear-gradient(rgba($background-color, 0), $background-color 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba($shadow-color, $shadow-intensity), rgba($shadow-color, 0));
  // also add button shadow:
  //radial-gradient(farthest-side at 50% 100%, rgba($shadow-color,$shadow-intensity), rgba($shadow-color,0)) 0 100%;
  background-repeat: no-repeat;
  background-color: $background-color;
  background-size: 100% $cover-size, 100% $cover-size, 100% $shadow-size, 100% $shadow-size;
  // Opera doesn't support this in the shorthand
  background-attachment: local, local, scroll, scroll;
}

* {
  box-sizing: border-box;
}

body {
  background: skyblue;
  font: 12px / 16px 'Noto Sans', sans-serif;
}

.teacher-chat {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  // position: fixed;
  bottom: 70px;
  right: 10px;
  width: 40px;
  height: 40px;
  //transform: translateY(70px);
  transition: all 250ms ease-out;
  border-radius: 50%;
  border $chat-messages-border-color solid 1px
  //opacity: 0;
  fancy-background()

  &__title {
    font font-opensans-bold
    position absolute
    top -35px
    color black
    font-size 18px
  }
  @media (max-width 350px) {
    &__title {
      top -55px
    }
  }

  &.enter:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    opacity: 1;
  }

  &.enter {
    transform: translateY(0);
    opacity: 0.6;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.14);
  }

  &.expand {
    width: 90%;
    bottom: 0;
    right: 0;
    //max-height: 400px;
    height: 80%;
    border-radius: 5px;
    cursor: auto;
    opacity: 1;
  }

  :focus {
    outline: 0;
    box-shadow: 0 0 3pt 2pt rgba(#0EC879, 0.3);
  }

  .icon-message-board {
    color color-7
    padding 12px
  }

  button {
    background: transparent;
    border: 0;
    color: white;
    text-transform: uppercase;
    border-radius: 3px;
    cursor: pointer;
  }

  .chat {
    display: none;
    flex-direction: column;
    position: absolute;
    opacity: 0;
    width: 1px;
    height: 1px;
    border-radius: 50%;
    transition: all 250ms ease-out;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &.enter {
      opacity: 1;
      display: flex;
      border-radius: 0;
      margin: 10px;
      width: auto;
      height: auto;
    }

    .header {
      flex-shrink: 0;
      padding-bottom: 10px;
      background: transparent;

      .title {
        padding: 0 5px;
        color color-8
      }

      button {
        flex-shrink: 0;
        color color-8
      }
    }

    .messages {
      position: relative;
      padding: 10px;
      margin: 0;
      list-style: none;
      overflow-y: scroll;
      overflow-x: hidden;
      flex-grow: 1;
      border-radius: 4px;
      background: transparent;
      padding-left 45px
      fancy-scrollbar()

      .attachment {
        display: block;
        margin-bottom: 20px;
        overflow-wrap: break-word;

        &:focus {
          box-shadow: none;
        }
      }

      li {
        width 100%
        display inline-block
        margin 0
      }

      .text {
        padding: 14px;
        margin: 0 0 20px 0;
        border-radius: 10px;
        max-width: 80%;
        min-width: 50%;
        width: fit-content;
        word-wrap: break-word;
        white-space: pre-wrap;
        font: font-opensans-regular;
        float: left

        .text-message {
          word-wrap: break-word;
          white-space: pre-wrap;
          font: font-opensans-regular;
          margin: 0 0 8px 0
        }

        .text-time {
          font-size: 12px;
          font-weight: 600;
          float right
        }
      }

      &-partner {
        animation: show-chat-odd 0.15s 1 ease-in;
        -moz-animation: show-chat-odd 0.15s 1 ease-in;
        -webkit-animation: show-chat-odd 0.15s 1 ease-in;
        //margin-right: $chat-thread-offset;
        font font-opensans-bold


        .b-avatar {
          position absolute
          left 0

        }

        .text {
          color: $chat-messages-partner-color;
          background-color: $chat-messages-partner-bg-color;
        }

        &.read {
          font font-opensans-regular
        }
      }

      &-self {
        animation: show-chat-even 0.15s 1 ease-in;
        -moz-animation: show-chat-even 0.15s 1 ease-in;
        -webkit-animation: show-chat-even 0.15s 1 ease-in;
        //margin-left: $chat-thread-offset;

        .text {
          color: $chat-messages-color;
          background-color: $chat-messages-bg-color;
          float right;

          .text-message {
            word-wrap: break-word;
            white-space: pre-wrap;
            font: font-opensans-regular;
            color: #fff
          }
        }
      }

      .name-partner {
        margin 0
        padding 0
        text-transform capitalize
        font font-opensans-semibold
        font-size 14px
        text-overflow ellipsis
        white-space nowrap
        overflow hidden
      }
      //li.partner:before {
      //  right: -$chat-thread-offset;
      //  // Placeholder avatar 1
      //  background-image: url(https://github.com/Thatkookooguy.png);
      //}


      //li.self:before {
      //  left: -$chat-thread-offset;
      //  // Placeholder avatar 2
      //  background-image: url(https://github.com/ortichon.png);
      //}
    }

    .footer {
      flex-shrink: 0;
      display: flex;
      //flex-direction: row-reverse;
      padding-top: 10px;
      position: relative;
      background: transparent;
      align-items: flex-end

      .text-box {
        border-radius: 3px;
        background: white;
        border $chat-messages-border-color solid 1px
        min-height: 100%;
        width: 100%;
        margin-right: 5px;
        color: $chat-messages-bg-color;
        overflow-y: auto;
        padding: 2px 5px;
        resize: none
        margin-bottom 0
        fancy-scrollbar()
      }

      .button-upload {
        margin-right 5px
        display block
      }

      .container-text-area {
        width 100%
        margin-right 5px
      }

      #chat-attachment {
        max-height 28px
        width 100%

        ::v-deep {
          .form-upload-file {
            min-height unset

            .uploaded-file {
              width 90% !important
            }
          }

          .invalid-feedback {
            display none !important
          }
        }

        .fa-times {
          position absolute
          bottom 9px
          right 7px
          color color-8
          cursor pointer
        }
      }

      button {
        border $chat-messages-border-color solid 1px
        color: $chat-messages-bg-color;
      }
    }
  }
}

// Animation
// --------------------------------------
@keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}

@-moz-keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}

@-webkit-keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}

@-moz-keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}

@-webkit-keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}
</style>
