import { render, staticRenderFns } from "./ClassroomDetailTabItemStudents.vue?vue&type=template&id=db03d540&scoped=true&"
import script from "./ClassroomDetailTabItemStudents.vue?vue&type=script&lang=js&"
export * from "./ClassroomDetailTabItemStudents.vue?vue&type=script&lang=js&"
import style0 from "./ClassroomDetailTabItemStudents.vue?vue&type=style&index=0&id=db03d540&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db03d540",
  null
  
)

export default component.exports