import {
  FETCH_CLASSROOMS_START,
  FETCH_CLASSROOMS_DATA
} from "@/modules/MyClassrooms/Storage/mutation-types";

export const
  mutations = {
    [FETCH_CLASSROOMS_START] (state) {
      state.loadingClassrooms = true
    },
    [FETCH_CLASSROOMS_DATA] (state, classrooms) {
      state.classrooms = classrooms
      state.loadingClassrooms = false
    }
  }
